import React from 'react';
import './App.css';
import { useFormFields, useMailChimpForm } from "use-mailchimp-form";
import ImageLogo from "./we_sell_travel_logo.png";

function App() {

  const url = process.env.REACT_APP_MAILCHIMP_SUBSCRIBE_URL;
  // The url looks like the url below:
  // https://aaaaaaaaa.us20.list-manage.com/subscribe/post?u=xxxxxxxxxxxxxxxxxx&amp;id=yyyyyyyyyy
  const {
      loading,
      error,
      success,
      message,
      handleSubmit
    } = useMailChimpForm(url);
  const { fields, handleFieldChange } = useFormFields({
    EMAIL: "",
  });

  return (
    <div className="App">
      <header className="App-header">
        <div className="Header-text">
            <img src={ImageLogo} alt="we sell travel logo" className="we-sell-travel-logo"/>
            <p>Signup to receive our travel deals newsletter.</p>  
        </div>
        <div id="mc_embed_signup">
        <form
          onSubmit={event => {
            event.preventDefault();
            handleSubmit(fields);
          }}
        >
          <input
            id="EMAIL"
            autoFocus
            type="email"
            value={fields.EMAIL}
            onChange={handleFieldChange}
          />
          <button>submit</button>
        </form>
        {loading && "submitting"}
        {error && message}
        {success && message}
      </div>
      </header>
    </div>
  );
}

export default App;
